<template>
  <div class="fans">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">关注</div>
      <div class="rightBtn"></div>
    </div>
    <div class="main">
      <PullRefresh
        :loading="loading"
        :refreshing="refreshing"
        :finished="finished"
        @onLoad="onLoad"
        @onRefresh="onRefresh"
        :isHigehtMax="true"
        :error="error"
        :isNoData="isNoData"
      >
        <div class="userItem" v-for="(item, index) in list" :key="item.uid">
          <router-link tag="div" :to="`/userHomePage?uid=${item.uid}`" class="avatarBox" :class="{ isCertifica: item.merchantUser == 1 }">
            <ImgDecypt class="userAvatar" :src="item.portrait" round />
            <div class="certification" v-if="item.superUser == 1">
              <Vlogo :vid="item.uid" />
            </div>
            <svg-icon class="certificaBorder" iconClass="certificaBorder" v-if="item.merchantUser == 1" />
          </router-link>
          <div class="userInfo">
            <div class="topBox">
              <router-link tag="div" :to="`/userHomePage?uid=${item.uid}`" class="userName" :class="{ vipName: item.isVip && item.vipLevel }">
                {{ item.name }}
              </router-link>
              <svg-icon class="userIcon" iconClass="crown" v-if="item.isVip && item.vipLevel > 0"></svg-icon>
              <div class="userIcon" v-for="icon in userIcons(item)" :key="icon.number">
                <ImgDecypt :src="icon.imgUrl" v-if="icon.isExpire" />
              </div>
            </div>
            <div class="fansNum">粉丝：{{ item.fans | watchCount }}</div>
          </div>
          <div :class="item.isFollow ? 'followed' : 'follow'" @click="careOrcancle(item, index)">
            {{ item.isFollow ? '已关注' : '关注' }}
          </div>
        </div>
      </PullRefresh>
    </div>
  </div>
</template>

<script>
import Vlogo from '@/components/Vlogo';
import ImgDecypt from '@/components/ImgDecypt';
import PullRefresh from '@/components/PullRefresh';
import { getMedal } from '@/utils/getConfig';
import { queryFollowList } from '@/api/mine';
import { careOrcancle } from '@/api/user';
import { Toast } from 'vant';
export default {
  name: 'Fans',
  components: {
    Vlogo,
    ImgDecypt,
    PullRefresh,
  },
  data() {
    return {
      list: [],
      pageNumber: 1,
      pageSize: 10,
      loading: true,
      refreshing: false,
      finished: false,
      error: false,
      isNoData: false,
      userIconsList: [],
    };
  },
  created() {
    this.getList();
  },
  methods: {
    userIcons(item) {
      return getMedal(item?.awardsExpire || []) || [];
    },
    // 查询列表
    async getList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
        uid: this.$route.query.uid ? this.$route.query.uid : undefined,
      };
      try {
        let res = await this.$Api(queryFollowList, req);
        this.loading = false;
        this.refreshing = false;
        if (res.code === 200) {
          let list = res.data.list || [];
          list.forEach((i) => {
            i.isFollow = true;
          });
          if (type === 'refresh') {
            this.list = list;
          } else {
            this.list = this.list.concat(list);
          }
          if (this.list.length <= 0) {
            this.isNoData = true;
          }
          if (!res.data.hasNext) {
            this.finished = true;
            return;
          }
        } else {
          this.error = true;
        }
      } catch (error) {
        this.error = true;
        this.loading = false;
      }
    },
    // 上啦加载
    onLoad() {
      this.loading = true;
      if (this.error) {
        this.error = false;
      } else {
        this.pageNumber++;
      }
      this.getList();
    },
    // 下拉刷新
    onRefresh() {
      this.pageNumber = 1;
      this.refreshing = true;
      this.finished = false;
      this.loading = true;
      this.getList('refresh');
    },
    // 关注-取消关注
    async careOrcancle(item, index) {
      let req = {
        followUID: parseInt(item.uid),
        isFollow: !item.isFollow,
      };
      if (!item.uid) {
        Toast('当前用户信息错误');
        return;
      }
      this.$store.commit('app/SET_LOADING', true);
      try {
        let res = await this.$Api(careOrcancle, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res.code === 200) {
          Toast(req.isFollow ? '关注成功' : '取消成功');
          item.isFollow = req.isFollow;

          return;
        }
        Toast(res.tip || `${req.isFollow ? '关注失败' : '取消失败'}`);
      } catch (error) {
        this.$store.commit('app/SET_LOADING', true);
        Toast('操作失败');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.fans {
  height: 100%;

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 80px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 80px;
    }
  }

  .main {
    height: calc(100% - 44px);
    .userItem {
      margin-top: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #fff;
      padding: 10px 12px;
      .avatarBox {
        position: relative;
        .userAvatar {
          width: 50px;
          height: 50px;
        }

        .certification {
          position: absolute;
          right: 0;
          bottom: 0;
          width: 15px;
          height: 15px;
          overflow: hidden;
          border-radius: 50%;
          -webkit-backface-visibility: hidden;
          -moz-backface-visibility: hidden;
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
        }

        .certificaBorder {
          position: absolute;
          top: -18px;
          width: 100%;
          font-size: 50px;
          z-index: 1;
        }
      }

      .isCertifica {
        border-radius: 50%;
        border: 2px solid #d82d0b;
      }

      .userInfo {
        flex: 2;
        margin: 0 8.8px;
        font-size: 15px;

        .topBox {
          display: flex;
          .userName {
            margin-right: 10px;
            font-size: 18px;
          }
          .vipName {
            color: #ff678f;
          }
          .userIcon {
            width: 18px;
            height: 18px;
            margin-right: 10px;
            /deep/ .van-image {
              background: #00000000 !important;
            }
          }
        }

        .fansNum {
          color: #4e586e;
          margin-top: 5px;
          font-size: 11px;
        }
      }

      .follow {
        width: 68px;
        height: 26px;
        line-height: 24px;
        border-radius: 24px;
        font-size: 14px;
        text-align: center;
        color: #fff;
        background: rgb(255, 103, 143);
      }

      .followed {
        width: 66px;
        height: 24px;
        line-height: 24px;
        border-radius: 24px;
        font-size: 14px;
        text-align: center;
        color: rgb(255, 103, 143);
        border: 1px solid rgb(255, 103, 143);
      }
    }
  }
}
</style>
